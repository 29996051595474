.unAuth {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
  position: relative;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-color: $blue;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: $mobile) {
    flex-direction: column;
  }

  &-login {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      padding: 2rem;

      height: 50vh;
    }

    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__logo {
        width: 25rem;
        margin-bottom: 2rem;
      }

      &__header {
        margin-bottom: .5rem;
        font-weight: bold;
      }

      &__button {
        font-family: 'Segoe UI';
        font-weight: 600;
        color: #5E5E5E;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #8c8c8c;
        border-radius: 0;
        width: fit-content;
        padding: .5rem;
        transition: .3s;
        cursor: pointer;

        &:hover {
          background-color: $main;
          color: white;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &-blue {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    align-items: center;
    background-color: $main;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      height: 50vh;
    }

    &__img {
      width: 100%;
      -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
    }
  }
}

.selector {
  background-color: $blue;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &-container {
    background-color: $main;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 25px;
    &__text {
      margin-bottom: 2rem;
      font-weight: bold;
      font-size: 20px;
      margin-top: .5rem;
      color: white;
    }
    &-item {
      padding: 5rem;
      font-size: 35px;
      text-decoration: none;
      align-items: center;
      font-weight: bold;
      display: flex;
      border-radius: 25px;
      flex-direction: column;
      cursor: pointer;
      color: darkgray;
      transition: .5s;
      svg {
        margin-bottom: .5rem;
      }
      &:hover {
        color: white;
        box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
      }
    }
  }
}