.dash-all {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $tablet) {
        flex-direction: row;
        width: 100%;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: $mobile) {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    justify-content: space-between;

    &-card {
        background-color: $main;
        border-radius: 10px;
        display: flex;
        height: 30%;
        @media only screen and (max-width: $tablet) {
            height: 100%;
            width: 16rem;
            padding: 2rem;
            margin-right: 1rem;
            &:last-of-type {
                margin-right: 0;
            }
        }
        @media only screen and (max-width: $mobile) {
            width: 100%;
            margin-bottom: 1rem;
        }
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__number {
            color: white;
            font-weight: 900;
            font-size: 80px;
        }

        &__text {
            color: white;
            font-weight: 700;
            font-size: 20px;
        }
    }
}

.dash-cal {
    height: 72vh;
    position: sticky;
    top: 2rem;
    &--edit {
        height: 50vh;
    }
}