.subLinks {
    display: flex;
    margin-top: 1rem;
    flex-direction: row;
    @media only screen and (max-width: $mobile) {
        flex-direction: column;
    }
    &__link {
        padding: .5rem;
        background-color: $lightAccent;
        margin-right: .5rem;
        min-width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: $accent;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
        @media only screen and (max-width: $mobile) {
            margin-bottom: .5rem;
        }
        &--active {
            background-color: $main;
            color: white;
        }
    }
}