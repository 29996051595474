.page {
    &-stick {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
        background-color: white;
    }
    &-extra {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1rem 2rem;
        padding-top: 0;
        border-bottom: 2px solid $lightAccent;
    }
    &-content {
        padding: 2rem;
        padding-top: 9rem;
        height: calc(100vh - 5rem);
        @media only screen and (max-width: $mobile) {
            padding-bottom: 9rem;
            padding-top: 9rem;
            height: auto;
        }
    }
    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 2rem;
        padding-bottom: 0;
        &__logo {
            height: 3rem;
            width: auto;
            margin-right: 1rem;
        }
        &__subtitle {
            font-weight: 700;
        }
        &__title {
            font-size: 2rem;
            color: $main;
            font-weight: 900;
        }
        &__icon {
            margin-right: .3rem;
        }
        &__user {
            color: $accent;
            font-weight: 500;
        }
    }
    &-links {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: space-evenly;
        padding: 2rem;
        background-color: white;
        border-top: 2px solid $lightAccent;
        @media only screen and (max-width: $mobile) {
            padding: 1rem;
            justify-content: space-between;
            height: auto;
        }
        &__link {
            color: $accent !important;
            text-decoration: none;
            cursor: pointer;
            font-weight: 500;
            @media only screen and (max-width: $mobile) {
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            &--active {
                color: $main !important;
            }
        }
    }
}