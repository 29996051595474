.dateItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: .5rem;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: .5s;
    &:hover {
        background-color: rgb(228, 228, 228);
    }
    &-content {
        display: flex;
        flex-direction: row;

        &-date {
            background-color: $main;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: .5rem;
            min-width: 4rem;
            min-height: 4rem;
            border-radius: 10px;
            margin-right: .5rem;
            @media only screen and (max-width: $mobile) {
                max-height: 4rem;
            }
            &__num {
                color: white;
                font-weight: 700;
                font-size: 30px;
                line-height: 0.7;
            }

            &__mon {
                color: white;
                font-weight: 400;
                font-size: 20px;
            }
        }

        &-info {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            &__title {
                font-weight: 900;
                font-size: 22px;
                @media only screen and (max-width: $mobile) {
                    font-size: 18px;
                }
            }
        }
    }
}

.dateItem-delete {
    background-color: #e65454;
}