@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$tablet: 1025px;
$mobile: 480px;

body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: white;
  scrollbar-width: thin !important;
}

.h-60  {
  height: 60%;
}

.w-fc {
  width: fit-content !important;
}

$main: #130922;
$accent: #8F8F8F;
$blue: #b4d2d9;
$lightAccent: #EDEDED;
$green: #61C655;
$red: #E76453;

.form-label {
  color: $main;
  font-weight: 700;
}

.font-bold {
  font-weight: bold;
}

.buttonHS {
  width: 100%;
  height: fit-content;
  background-color: $main;
  border-color: $main;
  white-space: nowrap;
  text-align: center !important;
  &:hover {
    background-color: $main;
    border-color: $main;
  }
  &--right {
    margin-right: .5rem;
  }
  &--left {
    margin-left: .5rem;
  }
  &--green {
    background-color: $green;
    border-color: $green;
    &:hover {
      background-color: $green;
      border-color: $green;
    }
  }
  &--red {
    background-color: $red;
    border-color: $red;
    &:hover {
      background-color: $red;
      border-color: $red;
    }
  }
}

.search {
  width: 20rem;
}

.temp {
  font-size: 20px;
}

.pb-7 {
  padding-bottom: 7rem;
}

.list-style {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: .5rem;
}

.MuiDataGrid-row {
  cursor: pointer !important;
}

.ml-2 {
  margin-left: 1rem;
}

.required {
  background-color: #f87260;
  border: 3px solid #e25846;
  color: #861000;
  padding: .7rem;
}

.rbc-event {
  background-color: $blue !important;
  color: $main;
  &:focus {
    outline-color: $blue !important;
  }
}

.graph-dim {
  height: calc(100vh - 16rem);
  width: calc(100vw - 4rem);
}

.backLink {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  svg {
    margin-right: .3rem;
  }
}