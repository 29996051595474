.status {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--red {
        color: #e65454;
    }
    &--orange {
        color: #f89748;
    }
    &--green {
        color: #75ca6a;
    }
    &__text {
        padding-top: .1rem;
    }

    svg {
margin-right: .2rem;
    }
}